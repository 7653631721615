import { Grid, Heading, Text } from '@chakra-ui/react';
import { ClockIcon, GiftIcon, WalletIcon } from '@/theme/Icons';
import { ShowMore } from '@/components/More/ShowMore';
import WithFeaturesDisable from '@/hoc/WithFeaturesDisable';
import OriaBackground from '@/components/Videos/OriaBackground';
import dynamic from 'next/dynamic';
import { ChevronButton } from '@/components/Button/ChevronButton';
import { DomainLicense, getFullUrlByLicense } from '@/utils/multiDomains';
import { getLocaleTranslation } from '@/helpers/translation';
import { TournamentType } from '@/components/GamesCatalog/types';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { SuggestedGame } from '@/components/GamesCatalog';
import {
  TournamentHero,
  TournamentInfo,
  TournamentInfoVariants,
  TournamentRanking,
  TournamentSection
} from '@/components/Tournaments';
import { HtmlContent } from '@/components/Content';
import {
  getTournamentDayLabel,
  getTournamentStatus
} from '@/helpers/tournaments';
import { toRem } from '@/helpers/toRem';
import SEO from '@/components/SEO';
import { getFallbackLanguage } from '@/helpers/lang';
import { NavBar } from '@/components/Navbar';
import { PortalHeader } from '@/components/Portal';

const DynamicCatalogBackground = dynamic(
  () => import('@/components/GoldenPalace/CatalogBackground')
);

type TournamentDetailProps = {
  license: keyof DomainLicense;
  locale: string;
  tournament: TournamentType;
};

export const TournamentDetail = ({
  license,
  locale,
  tournament
}: TournamentDetailProps) => {
  const {
    games,
    providers,
    rankings,
    winnerCount,
    updatedAt,
    isRankingFinished
  } = tournament;

  const router = useRouter();
  const { t } = useTranslation();

  const tournamentsBaseUrl = `${getFullUrlByLicense(
    license,
    locale
  )}/tournaments`;

  const tournamentTranslation = getLocaleTranslation(
    tournament.translations!,
    getFallbackLanguage(locale)
  );

  const seoContent = {
    slug: tournamentTranslation?.slug,
    descriptionHeader: tournamentTranslation?.descriptionHeader || '',
    descriptionLead: tournamentTranslation?.descriptionLead || '',
    descriptionContent: tournamentTranslation?.descriptionContent || '',
    metaDescription: tournamentTranslation?.metaDescription || '',
    metaKeywords: tournamentTranslation?.metaKeywords || '',
    metaTitle: tournamentTranslation?.metaTitle || '',
    name: tournamentTranslation?.name || ''
  };

  useEffect(() => {
    const newUrl = `${tournamentsBaseUrl}/${tournament.id}/${tournamentTranslation?.slug}`;
    router.push(newUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.locale]);

  const labelDays = getTournamentDayLabel(tournament);

  const enableBackground = license !== 'F';

  return (
    <>
      <SEO
        title={seoContent.metaTitle || seoContent.name}
        description={seoContent.metaDescription}
        keywords={seoContent.metaKeywords}
        image={tournament?.mediaUrls?.main?.md}
        // Because the image comes from backoffice, we do not have any size in API.
        // 1440 x 669 (cfr. in backOffice service, app/games/tournaments/(components)/filesRules.ts)
        imageWidth="1440"
        imageHeight="669"
      />

      <PortalHeader>
        <NavBar basic />
      </PortalHeader>

      {enableBackground &&
        WithFeaturesDisable(<OriaBackground />, 'OriaBackground')}
      {enableBackground &&
        WithFeaturesDisable(<DynamicCatalogBackground />, 'CatalogBackground')}

      <TournamentSection name="back" pt={40} isRow>
        <ChevronButton iconLeft="left" as="a" href={tournamentsBaseUrl}>
          <Text>{t('tournament:goBackToTournament')}</Text>
        </ChevronButton>
      </TournamentSection>

      <TournamentHero
        name={tournamentTranslation?.name}
        title={tournamentTranslation?.title}
        images={tournament?.mediaUrls?.main}
      />

      {/* TIME / PRIZE / MINIMUM BET */}

      <TournamentSection name="info" withBottomBorder isRow>
        <TournamentInfo
          variant={TournamentInfoVariants.BASE}
          text={t(labelDays.key, labelDays.options)}
          withFullText
          w={['100%', '100%', 'auto']}
        >
          <ClockIcon
            width={'1.5rem'}
            height={'1.5rem'}
            marginRight={['1rem']}
          />
        </TournamentInfo>

        <Heading
          display={['block', 'block', 'none']}
          mt="2"
          fontSize={toRem(24)}
          lineHeight={toRem(32)}
          w={'100%'}
        >
          {tournamentTranslation?.title}
        </Heading>

        <TournamentInfo
          variant={TournamentInfoVariants.BASE}
          ml={['0', '0', 'auto']}
          mt={[8, 8, '0']}
          label={t('tournament:prize')}
          text={tournamentTranslation?.prize || ''}
          w={['100%', 'auto']}
        >
          <GiftIcon
            width={'1.5rem'}
            height={'1.5rem'}
            marginRight={['1rem']}
            opacity={0.4}
          />
        </TournamentInfo>

        <TournamentInfo
          variant={TournamentInfoVariants.BASE}
          ml={['auto', 'auto', '4']}
          mt={[4, 8, '0']}
          w={['100%', 'auto']}
          label={t('tournament:minimumBet')}
          text={`€${tournament.minimumStake / 100}`}
        >
          <WalletIcon
            width={'1.5rem'}
            height={'1.5rem'}
            marginRight={['1rem']}
            opacity={0.4}
          />
        </TournamentInfo>
      </TournamentSection>

      {/* DESCRIPTION / TERMS */}

      <TournamentSection name="content" mt="16" maxWidth={'70ch'}>
        <Heading
          as="h4"
          fontSize={[toRem(18), toRem(24)]}
          lineHeight={[toRem(24), toRem(30)]}
        >
          {t('tournament:howDoesItWork')}
        </Heading>

        <HtmlContent
          html={tournamentTranslation?.descriptionContent || ''}
          size="body"
          color={'figma.neutral.400'}
          marginTop={['1rem']}
        />

        <ShowMore
          buttonProps={{ my: 12 }}
          buttonLabelHide={t('tournament:hideTermsAndConditions')}
          buttonLabelShow={t('tournament:showTermsAndConditions')}
        >
          <HtmlContent
            html={tournamentTranslation?.termsAndConditions || ''}
            size="body"
            color={'figma.neutral.400'}
            mb={12}
          />
        </ShowMore>
      </TournamentSection>

      {/* GAMES THAT PARTICIPATE */}

      <TournamentSection name="games" withTopBorder withBottomBorder>
        <Heading
          as="h4"
          fontSize={[toRem(18), toRem(24)]}
          lineHeight={[toRem(24), toRem(30)]}
          mb="10"
        >
          {t('tournament:gamesThatParticipate')}
        </Heading>
        <Grid
          templateColumns={['1fr 1fr', '1fr 1fr 1fr', '1fr 1fr 1fr 1fr']}
          gap="5" // 40px
          w={'100%'}
        >
          {games.map((game, index) => {
            const providerName = providers.find(
              (provider) => provider['@id'] === game.provider
            )?.name;
            return (
              <SuggestedGame
                key={game['@id']}
                game={game}
                providerName={providerName}
                tournament={tournament.id}
                data-testid={'see-all-game-idx-' + index}
              />
            );
          })}
        </Grid>
      </TournamentSection>

      {/* RANKING */}

      <TournamentSection
        name="ranking"
        alignItems={'stretch'}
        maxWidth={'70ch'}
      >
        <TournamentRanking
          hasLeaderboard={tournament?.hasLeaderboard}
          winnerCount={winnerCount}
          updatedAt={updatedAt}
          ranking={rankings}
          perPage={10}
          isRankingFinished={isRankingFinished}
          tournamentStatus={getTournamentStatus(tournament)}
        />
      </TournamentSection>
    </>
  );
};
